import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "explore-page" }
const _hoisted_2 = { class: "title-section" }
const _hoisted_3 = { class: "filter-buttons" }
const _hoisted_4 = { class: "search-section" }
const _hoisted_5 = { class: "applets-section" }
const _hoisted_6 = { class: "applets-container" }
const _hoisted_7 = {
  class: "applets-grid",
  ref: "appletsGrid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppletTile = _resolveComponent("AppletTile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeader, { backgroundColor: "#f0f0f0" }),
    _createVNode(_component_Header),
    _createElementVNode("section", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "section-title" }, "Explore", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/explore/all",
          class: _normalizeClass(["filter-btn", { active: _ctx.$route.path === '/explore/all' }])
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("All")
          ])),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_router_link, {
          to: "/explore/applets",
          class: _normalizeClass(["filter-btn", { active: _ctx.$route.path === '/explore/applets' }])
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Applets")
          ])),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_router_link, {
          to: "/explore/services",
          class: _normalizeClass(["filter-btn active", { active: _ctx.$route.path === '/explore/services' }])
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Services")
          ])),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "search-bar",
        placeholder: "Search Applets or services",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateSearchQuery && _ctx.updateSearchQuery(...args)))
      }, null, 544), [
        [_vModelText, _ctx.searchQuery]
      ]),
      _createElementVNode("button", {
        class: "clear-search",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearSearchQuery && _ctx.clearSearchQuery(...args)))
      }, "✖")
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredApplets, (applet, index) => {
            return (_openBlock(), _createBlock(_component_AppletTile, {
              key: index,
              title: applet.title,
              description: applet.description,
              "background-color": applet.color
            }, null, 8, ["title", "description", "background-color"]))
          }), 128))
        ], 512)
      ])
    ])
  ]))
}