import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "password-container" }
const _hoisted_2 = ["type"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "password-container" }
const _hoisted_5 = ["type"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "social-login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthButton = _resolveComponent("AuthButton")!
  const _component_AuthLayout = _resolveComponent("AuthLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[7] || (_cache[7] = _createElementVNode("img", {
      src: _imports_0,
      class: "ifttt-logo"
    }, null, -1)),
    _createVNode(_component_AuthLayout, {
      title: "Sign Up",
      linkText: "Already have an account? Sign in here",
      linkPath: "/sign-in"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitSignUp && _ctx.submitSignUp(...args)), ["prevent"])),
          class: "sign-up-form"
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            type: "email",
            placeholder: "Email",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.email]
          ]),
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("input", {
              type: _ctx.showPassword ? 'text' : 'password',
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
              placeholder: "Password",
              required: ""
            }, null, 8, _hoisted_2), [
              [_vModelDynamic, _ctx.password]
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "toggle-password",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.togglePassword && _ctx.togglePassword(...args)))
            }, [
              _createElementVNode("img", {
                src: _ctx.showPassword ? _ctx.showIcon : _ctx.hideIcon,
                alt: "toggle password visibility"
              }, null, 8, _hoisted_3)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: _ctx.showConfirmPassword ? 'text' : 'password',
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.confirmPassword) = $event)),
              placeholder: "Confirm Password",
              required: ""
            }, null, 8, _hoisted_5), [
              [_vModelDynamic, _ctx.confirmPassword]
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "toggle-password",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleConfirmPassword && _ctx.toggleConfirmPassword(...args)))
            }, [
              _createElementVNode("img", {
                src: _ctx.showConfirmPassword ? _ctx.showIcon : _ctx.hideIcon,
                alt: "toggle confirm password visibility"
              }, null, 8, _hoisted_6)
            ])
          ]),
          _createVNode(_component_AuthButton, { text: "Get started" })
        ], 32),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "separator" }, "Or", -1)),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_AuthButton, {
            text: "Continue with Google",
            buttonColor: '#f4fefe',
            textColor: 'fff',
            icon: _ctx.GoogleIcon
          }, null, 8, ["icon"]),
          _createVNode(_component_AuthButton, {
            text: "Continue with GitHub",
            buttonColor: '#303030',
            icon: _ctx.GithubIcon
          }, null, 8, ["icon"])
        ])
      ]),
      _: 1
    })
  ], 64))
}