import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/profile-icon.svg'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("img", {
      src: _imports_0,
      alt: "Logo",
      class: "logo"
    }, null, -1)),
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/explore",
        class: "nav-item"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Explore")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/create",
        class: "nav-item create-btn"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Create")
        ])),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_1,
        alt: "Profile",
        class: "profile-icon"
      }, null, -1))
    ])
  ]))
}