import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/profile-icon.svg'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = { class: "applet-title" }
const _hoisted_4 = { class: "applet-source" }
const _hoisted_5 = { class: "applet-stats" }
const _hoisted_6 = { class: "users-count" }
const _hoisted_7 = { class: "applet-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("nav", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: "/explore",
          class: "nav-item"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Explore")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/my-applets",
          class: "nav-item"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("MyApplets")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/create",
          class: "nav-item create-btn"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Create")
          ])),
          _: 1
        }),
        _cache[3] || (_cache[3] = _createElementVNode("img", {
          src: _imports_1,
          alt: "Profile",
          class: "profile-icon"
        }, null, -1))
      ])
    ]),
    _createElementVNode("div", {
      class: "applet-details",
      style: _normalizeStyle({ backgroundColor: _ctx.applet.color })
    }, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.applet.title), 1),
      _createElementVNode("p", _hoisted_4, "by " + _toDisplayString(_ctx.applet.source), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.applet.userCount) + " users", 1)
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("button", { class: "connect-btn" }, "Connect", -1)),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.applet.description), 1)
    ], 4)
  ]))
}